<template>
  <div class="modal-activity" v-if="isShow" bi-route="360act" >
    <div class="mains page1" v-show="page == 1">
      <img src="./images/icon2.png" class="b1" />
      <img src="./images/icon1.png" class="b2"  @click="handleClose" />
      <i bi-click="oneCloseBtn" class="close" ><IconFont type="icon-hide"  @click="handleClose"></IconFont></i>
    </div>
    <div class="mains page2" v-show="page == 2"  >
      <div class="b1"></div>
      <div class="zp">
        <a class="btn" @click="start"></a>
        <img  :style="`transform: ${rotate_deg}; transition:${rotate_transition};`" src="./images/icon8.png" />
      </div>
      <div class="swiperGoods">
        <div class="swiper-container " >
          <div class="swiper-wrapper">
            <div class="swiper-slide"><img src="./images/pic1.png" /></div>
            <div class="swiper-slide"><img src="./images/pic2.png" /></div>
            <div class="swiper-slide"><img src="./images/pic3.png" /></div>
            <div class="swiper-slide"><img src="./images/pic4.png" /></div>
            <div class="swiper-slide"><img src="./images/pic5.png" /></div>
            <div class="swiper-slide"><img src="./images/pic6.png" /></div>
            <div class="swiper-slide"><img src="./images/pic7.png" /></div>
          </div>
          
        </div>

        <div class="swiper-button-prev" @click="handleSwiperPrev"></div>
        <div class="swiper-button-next" @click="handleSwiperNext"></div>
      </div>
      
      <i bi-click="oneCloseBtn" class="close" ><IconFont type="icon-hide"  @click="handleClose"></IconFont></i>
    </div>
    <div class="mains page3" v-show="page == 3" @click="handleOpenAtc" >
        <a @click="handleOpenAtc"  bi-click="oneGoBtn" class="btn">
          <p class="b1"></p>
        </a>
        <div class="swiperGoods2">
            <div class="swiper-container " >
              <div class="swiper-wrapper">

                  <div class="swiper-slide"><img src="./images/pic7.png" /></div>
                  <div class="swiper-slide"><img src="./images/pic5.png" /></div>
                  <div class="swiper-slide"><img src="./images/pic2.png" /></div>
                  <div class="swiper-slide"><img src="./images/pic3.png" /></div>
                  <div class="swiper-slide"><img src="./images/pic6.png" /></div>
                  <div class="swiper-slide"><img src="./images/pic1.png" /></div>
                  <div class="swiper-slide"><img src="./images/pic4.png" /></div>
              </div>
            </div>
            <!-- <div class="swiper-button-prev" @click="handleSwiperPrev2"></div> -->
            <!-- <div class="swiper-button-next" @click="handleSwiperNext2"></div> -->
          </div>
          <i bi-click="oneCloseBtn" class="close" ><IconFont type="icon-hide"  @click="handleClose"></IconFont></i>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconFont from "@/components/IconFont.vue";
import { GetCookie, SetCookie } from "@/utils/utils";
import {importCDN} from "@/utils/utils";
export default {
  data() {
    return {
      isShow:!GetCookie('CMS-ACT-ONE-SHOP'),
      page:1,

      LuckyClick: 20,
      show:false,
      cat: 45, //总共8个扇形区域，每个区域约45度
      isAllowClick: true, //是否能够点击
      rotate_deg: 'rotate(0deg)', //指针旋转的角度
      rotate_transition: "transform 6s ease-in-out" //初始化选中的过度属性控制
    };
  },
  name: "",
  props: {
  },
  components: {
    IconFont
  },
  computed: {
    ...mapGetters(["marketGroup", "config"])
  },
  methods: {
    handleOpenAtc(){
      this.$router.push({
        path: `/zero`,
        query: {
        },
      });
      this.isShow = false;
    },
    handleClose(){
      if(this.page == 3){
        this.isShow = false;
        return false;
      }
      
      this.page = this.page + 1;
      clearTimeout(this.setAct);
      if(this.page == 2){
        setTimeout(()=>{
          this.swiperInit();
          this.start();
        },100)
      }
    },
    handleSwiperPrev(){
      this.swiperGoodsEvent.slidePrev();
    },
    handleSwiperNext(){
      this.swiperGoodsEvent.slideNext();
    },
    swiperInit(){
      this.swiperGoodsEvent = new Swiper('.swiperGoods .swiper-container', {
        slidesPerView: 5,
        noSwiping:true,
        loop: true,
        loopedSlides: 4,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        }
      });
    },
    handleSwiperPrev2(){
      this.swiperGoodsEvent.slidePrev();
    },
    handleSwiperNext2(){
      this.swiperGoodsEvent.slideNext();
    },
    swiperInit2(){
      this.swiperGoodsEvent2 = new Swiper('.swiperGoods2 .swiper-container', {
        slidesPerView: 4,
        loop: true,
        loopedSlides: 4,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        }
        // noSwiping:true,
      });
      
    },
    start() {
      if (this.LuckyClick == 0) {
        this.show = 1;
        return;
      }

      this.rotating();
    },
    rotating() {
        if (!this.isAllowClick) return;
        this.isAllowClick = false;
        this.rotate_transition = "transform 6s cubic-bezier(0.41, 0.15, 0.03, 0.94)";
        this.LuckyClick--;
        var rand_circle = 5; //默认多旋转5圈
        //   var winningIndex = Math.floor(Math.random() * 8); //获奖的下标   0-7   没有概率每个平均
        var winningIndex = this.set(); //设置了概率的

        var randomDeg = 360 - winningIndex * 45; //当前下标对应的角度    45是总共8个扇形区域，每个区域约45度
  
        var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
        this.rotate_deg = "rotate(" + deg + "deg)";
  
        var that = this;
        setTimeout(function() {
          // that.isAllowClick = true;
          that.page = 3;
        }, 7000);
      },
  
      //设置概率
      set() {
        var winIndex;
        winIndex = 0
        return winIndex;
      }
  },
  mounted() {
    if(!this.Swiper){
      importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(res=>{
        this.setAct = setTimeout(()=>{
          this.handleClose();
        },2000)
        
      })
    }else{
      this.setAct = setTimeout(()=>{
        this.handleClose();
      },2000)
    }

    SetCookie('CMS-ACT-ONE-SHOP',1, 24 - (new Date()).getHours())
  },
  destroyed() {
  },
  watch:{
    page(){
      if(this.page == 3){
        setTimeout(()=>{
          this.swiperInit2()
        },100);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

  @import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
  .modal-activity{
    background: rgba(0, 0, 0, .8);
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modal-activity .mains{
    position: relative;
    z-index: 0;
    
    .close{
      position:absolute;
      width: 37px; height: 37px; 
      *{
        font-size:35px;cursor: pointer;color:#fff;
      }
      &:hover{
        opacity: .9;
      }
    }

    &.page2{
      width: 100%;
      .close{
        left:50%;
        top:50px;
        margin-left:450px;
      }
      .b1{
        width: 100%;
        height: 99px;
        background:url(./images/icon3.png) no-repeat center center;
        background-size:auto 100%;
        position: relative;
        top: 50px; 
      }
      .zp{
        width: 545px;
        height: 538px;
        margin: auto;
        background:url(./images/icon4.png) no-repeat center center;
        background-size:auto 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        .btn{
          width: 136px;
          height: 155px;
          margin: auto;
          background:url(./images/icon7.png) no-repeat center center;
          background-size:auto 100%;
          position: absolute;
          left:50%;
          top:50%;
          z-index: 20;
          margin:-95px 0 0 -65px;
          cursor: pointer;
        }
        img{
          width: 288px;
          height: 288px;
          position: relative;
          z-index: 11;
          margin:auto;
          top:-4px;
          left:1px;
        }
      }
    }

    &.page1{
      width:100%;
      text-align: center;
      top:-50px;
      .b1{
        width: 878px; margin:auto;
      }
      .b2{
        width: 100%;margin:auto; cursor: pointer;
      }
      .close{
        left:50%; margin-left:500px;
        top:50%; margin-top:-200px;
      }
    }

    .swiperGoods,.swiperGoods2{
      width: 620px; 
      height: 100px;
      position: relative;
      z-index: 10;
      margin: auto;
      .swiper-wrapper{
        display: flex;
        justify-content: flex-start;
      }
      .swiper-container{
        width: 540px; 
      }
      .swiper-button-prev, .swiper-button-next{
        background: url(./images/icon5.png) no-repeat center center;
        width:22px;
        height:42px;
        background-size:100% auto;
      }
      .swiper-button-next{
        background-image: url(./images/icon6.png);
        right: 5px;
      }
      .swiper-slide{
        width: 110px; height: 100px;
        text-align: center;
        img{
          width: 100px;
        }
      }
    }

    .swiperGoods2{
      top: 260px;
      
      .swiper-container{
        width: 430px; 
      }
    }
    &.page3{
      width: 1096px;
      height: 575px;
      background:url(./images/icon9.png) no-repeat center center;
      background-size: 100% auto;
      position: relative;
      .close{
        left:50%;
        margin:-100px 0 0 380px;
        
      }
      .btn{
        width: 344px;
        height: 77px;
        cursor: pointer;
        position: absolute;
        z-index: 10;
        background:url(./images/icon10.png) no-repeat center center;
        background-size: 100% auto;
        left:50%;
        top:50%;
        margin: 145px 0 0 -170px;
        &:active{
          /* margin-top: 150px; */
          .b1{
            margin-top: 10px;
          }
        }
      }
      .b1{
        cursor: pointer;
        position: absolute;
        z-index: 10;
        background:url(./images/icon11.png) no-repeat center center;
        background-size: 100% auto;
        width: 96px;
        height: 96px;
        left:50%;
        top:50%;
        margin: 5px 0 0 70px;
      }
    }

  }
</style>
